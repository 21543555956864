package com.stevdza_san.platiparking.section.main

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.components.Header
import com.stevdza_san.platiparking.models.Language
import com.stevdza_san.platiparking.models.MainGradient
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.AppPreviewStyle
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.styles.StoreImageStyle
import com.stevdza_san.platiparking.util.Constants.PLAY_STORE_LINK
import com.stevdza_san.platiparking.util.Constants.SECTION_WIDTH
import com.stevdza_san.platiparking.util.Constants.SF_PRO_LIGHT
import com.stevdza_san.platiparking.util.Constants.SF_PRO_MEDIUM
import com.stevdza_san.platiparking.util.Constants.SF_PRO_REGULAR
import com.stevdza_san.platiparking.util.LocalizationManager
import com.stevdza_san.platiparking.util.Res
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.BackgroundClip
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection(
    breakpoint: Breakpoint,
    largeBreakpoint: Boolean,
    language: Language,
    onLanguageChange: (Language) -> Unit,
    onMenuClick: () -> Unit
) {
    Column(
        modifier = Modifier
            .id(Section.Main.id)
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(
                leftRight = if (breakpoint > Breakpoint.LG) 200.px
                else if (breakpoint == Breakpoint.LG) 150.px
                else if (breakpoint > Breakpoint.MD) 80.px
                else if (breakpoint == Breakpoint.MD) 60.px
                else 40.px
            ),
        horizontalAlignment = Alignment.Start
    ) {
        Header(
            largeBreakpoint = largeBreakpoint,
            language = language,
            onLanguageChange = onLanguageChange,
            onMenuClick = onMenuClick
        )
        SimpleGrid(
            modifier = Modifier.fillMaxWidth(),
            numColumns = numColumns(base = 1, xl = 2)
        ) {
            LeftSide(
                breakpoint = breakpoint,
                largeBreakpoint = largeBreakpoint
            )
            RightSide(largeBreakpoint = largeBreakpoint)
        }
    }
}

@Composable
private fun LeftSide(
    breakpoint: Breakpoint,
    largeBreakpoint: Boolean
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.Center
    ) {
        Surface(
            modifier = Modifier
                .height(if (largeBreakpoint) 100.px else 0.px)
        ) {}
        P(
            attrs = TextSelectionStyle.toModifier()
                .fillMaxWidth()
                .margin(bottom = 0.px)
                .backgroundImage(MainGradient)
                .backgroundClip(BackgroundClip.Text)
                .color(Color.transparent)
                .fontFamily(SF_PRO_MEDIUM)
                .fontSize(if (largeBreakpoint) 7.cssRem else 5.cssRem)
                .textAlign(
                    if (largeBreakpoint) TextAlign.Start
                    else TextAlign.Center
                )
                .toAttrs()
        ) {
            Text(value = LocalizationManager.getString(key = Section.Main.shortTitle))
        }
        P(
            attrs = TextSelectionStyle.toModifier()
                .fillMaxWidth()
                .margin(top = 0.px)
                .color(Theme.Text.rgb)
                .fontFamily(SF_PRO_MEDIUM)
                .fontSize(if (largeBreakpoint) 7.cssRem else 5.cssRem)
                .textAlign(
                    if (largeBreakpoint) TextAlign.Start
                    else TextAlign.Center
                )
                .toAttrs()
        ) {
            Text(value = LocalizationManager.getString(key = Section.Main.title))
        }
        P(
            attrs = TextSelectionStyle.toModifier()
                .fillMaxWidth()
                .margin(topBottom = 0.px)
                .color(Theme.TextLowVisibility.rgb)
                .fontFamily(SF_PRO_LIGHT)
                .fontSize(1.5.cssRem)
                .lineHeight(value = 1.6)
                .textAlign(
                    if (largeBreakpoint) TextAlign.Start
                    else TextAlign.Center
                )
                .toAttrs()
        ) {
            Text(
                value = LocalizationManager.getString(key = Section.Main.subtitle)
            )
        }
        Surface(modifier = Modifier.height(50.px)) {}
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = if (largeBreakpoint) Arrangement.Start
            else Arrangement.Center
        ) {
            Image(
                modifier = StoreImageStyle.toModifier()
                    .width(if (breakpoint <= Breakpoint.SM) 8.5.cssRem else 10.cssRem)
                    .cursor(Cursor.Pointer)
                    .onClick { window.open(url = PLAY_STORE_LINK) },
                src = Res.PLAY_STORE,
                alt = "Play Store Image"
            )
            Surface(modifier = Modifier.width(size = 20.px)) {}
            Image(
                modifier = TextSelectionStyle.toModifier()
                    .id("apple")
                    .width(if (breakpoint <= Breakpoint.SM) 8.5.cssRem else 10.cssRem),
                src = Res.APP_STORE,
                alt = "App Store Image"
            )
            Tooltip(
                modifier = Modifier
                    .padding(
                        topBottom = 4.px,
                        leftRight = 12.px
                    )
                    .borderRadius(r = 12.px)
                    .fontFamily(SF_PRO_REGULAR),
                target = ElementTarget.PreviousSibling,
                text = LocalizationManager.getString(key = "coming_soon")
            )
        }
    }
}

@Composable
private fun RightSide(largeBreakpoint: Boolean) {
    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = if (largeBreakpoint) Alignment.CenterStart else Alignment.Center
    ) {
        Image(
            modifier = AppPreviewStyle.toModifier()
                .margin(
                    top = if (largeBreakpoint) 0.px else 100.px,
                    left = if (largeBreakpoint) 100.px else 0.px
                )
                .fillMaxWidth()
                .maxWidth(600.px),
            src = Res.APP_PREVIEW,
            alt = "Application Preview"
        )
    }
}